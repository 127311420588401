<template>
	<div v-if="!resetPasswordSuccess">
		<h1 class="mb-10 text-3xl font-semibold text-center">
			{{ t("reset_password") }}
		</h1>
		<form
			class="form-wrapper"
			@submit.prevent="resetPassword"
		>
			<label class="block w-full mb-3">
				<div class="relative">
					<input
						class="w-full"
						:type="showPasswordField ? 'text' : 'password'"
						name="newPassword"
						:placeholder="t('new_password')"
						v-model="newPassword"
						@keyup.enter="resetPassword"
						autocomplete="new-password"
					/>
					<img
						:src="require(`@/assets/${showPasswordField ? 'password-visible' : 'password-hidden'}.svg`)"
						class="form-wrapper__icon"
						@click="togglePasswordField"
						alt="Horse Republic"
					/>
				</div>
				<span
					v-show="newPasswordError"
					class="form-wrapper__validation-error mt-1"
				>
					{{ newPasswordError }}
				</span>
			</label>

			<label class="block w-full mb-3">
				<div class="relative">
					<input
						class="w-full"
						:type="showPasswordField ? 'text' : 'password'"
						name="confirmPassword"
						:placeholder="t('confirm_password')"
						v-model="confirmPassword"
						@keyup.enter="resetPassword"
						autocomplete="new-password"
					/>
					<img
						:src="require(`@/assets/${showPasswordField ? 'password-visible' : 'password-hidden'}.svg`)"
						class="form-wrapper__icon"
						@click="togglePasswordField"
						alt="Horse Republic"
					/>
				</div>
				<span
					v-show="confirmPasswordError"
					class="form-wrapper__validation-error mt-1"
				>
					{{ confirmPasswordError }}
				</span>
			</label>
			<span
				v-show="apiError"
				class="form-wrapper__validation-error mb-3"
			>
				{{ apiError }}
			</span>

			<button
				class="form-wrapper__submit"
				:disabled="apiLoader"
			>
				{{ apiLoader ? t("loadingMessage") : t("submit") }}
			</button>
		</form>
	</div>
	<div
		class="p-5 text-center bg-hr-pink"
		v-else
	>
		<h1 class="mb-5 text-3xl text-center">
			{{ t("reset_password_success") }}
		</h1>
		<a
			:href="webLoginUrl"
			class="form-wrapper__link"
		>
			{{ t("back_to_login") }}
		</a>
	</div>
</template>

<script>
	import { ref, computed } from "vue";
	import { useRoute } from "vue-router";
	import { useI18n } from "vue-i18n";
	import { useForm, useField } from "vee-validate";
	import { object, string, ref as yupRef } from "yup";
	import axios from "axios";
	import useApi from "@/composables/useApi";

	export default {
		name: "ResetPassword",
		setup() {
			const route = useRoute();
			const { t, te } = useI18n();
			const { handleApi } = useApi();
			const apiError = ref(null);
			const apiLoader = ref(false);
			const showPasswordField = ref(false);
			const resetPasswordSuccess = ref(false);
			const webLoginUrl = computed(() => {
				return "https://horserepublic.app.link/TPgGJ94fZlb";
				//return `${process.env.VUE_APP_WEB_URL}/login`
			});

			const resetPasswordSchema = object({
				newPassword: string().required().min(6),
				confirmPassword: string()
					.required()
					.min(6)
					.oneOf([yupRef("newPassword"), null], t("validation.oneOf")),
			});
			const { handleSubmit } = useForm({
				validationSchema: resetPasswordSchema,
			});
			const { value: newPassword, errorMessage: newPasswordError } = useField("newPassword");
			const { value: confirmPassword, errorMessage: confirmPasswordError } = useField("confirmPassword");

			const togglePasswordField = () => {
				showPasswordField.value = !showPasswordField.value;
			};
			const resetPassword = handleSubmit(async () => {
				let payload = {
					key: route.query.key,
					password: newPassword.value,
					password_repeat: confirmPassword.value,
				};
				apiLoader.value = true;
				let [response, resetPasswordError] = await handleApi(
					axios.post(`${process.env.VUE_APP_AUTH_URL}/v1/password/reset`, payload)
				);
				if (resetPasswordError)
					apiError.value = te(`apiErrors.${resetPasswordError}`)
						? t(`apiErrors.${resetPasswordError}`)
						: t(`apiErrors.ERR_INVALID_BINDING_STRUCTURE`);
				if (response) resetPasswordSuccess.value = true;

				setTimeout(() => {
					apiLoader.value = false;
				}, 500);
			});

			return {
				t,
				newPassword,
				newPasswordError,
				confirmPassword,
				confirmPasswordError,
				togglePasswordField,
				resetPassword,
				apiError,
				apiLoader,
				showPasswordField,
				resetPasswordSuccess,
				webLoginUrl,
			};
		},
	};
</script>
